.login-container {

}

.unsubscribe-container {
  .introduction {
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(https://cdn.finzmo.com/ceres/unsubsribe.svg) !important;
    min-height: 240px;
  }
  .block-headline {
    margin-bottom: initial !important;
  }
  #content p {
    color: #2b2e42 !important;
  }
  .unsubscribe-message {
    margin: 1rem 0;
  }
  .unsubscribe-inputs {
    text-align: left;
  }
  form {
    margin-top: 2rem;
  }
  .unsubscribe-form-inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
