.calculator-container {
  position: relative;

  @media screen and (min-width: 576px) {
    padding-top: 2rem;
  }

  @media screen and (min-width: 1024px) {
    padding-top: 6rem;
  }

  .calculator-header {
    min-height: 236px;
    position: relative;
    align-items: flex-end;
    color: white;
    padding-bottom: 16px;

    .calculator-header-headline {
      z-index: 1;
      text-align: center;

      @media screen and (min-width: 576px) {
        text-align: unset;
      }
    }

    @media screen and (min-width: 576px) {
      color: var(	--fi-secondary);
    }

    @media screen and (min-width: 1024px) {
      align-items: initial;
    }

    h1 {
      color: inherit;
    }
  }

  .tab-bar {
    display: none;
    margin-top: 3rem;
    position: relative;

    @media screen and (min-width: 1024px) {
      display: inline-flex;
    }
  }

  .bg {
    display: none;
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    content: '';
    transition: opacity 1.0s ease;
    background-repeat: no-repeat;
    background-position: right 10%;
  }

  .bg-desktop {
    @media screen and (min-width: 1024px) {
      &.active {
        display: block;
      }
    }
  }

  .bg-tablet {
    background-size: contain;
    background-position: bottom right;

    @media screen and (min-width: 576px) {
      &.active {
        display: block;
      }
    }

    @media screen and (min-width: 1024px) {
      &.active {
        display: none;
      }
    }
  }

  .bg-mobile {
    background-size: cover;
    background-position: center top;

    &.active {
      display: block;
    }

    @media screen and (min-width: 576px) {
      &.active {
        display: none;
      }
    }
  }

  &:has(.calculator:nth-last-child(n + 2)) {
    .calculator {
      .product-header {
        display: block;
      }
    }
  }

  .calculator {
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media screen and (min-width: 576px) {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    @media screen and (max-width: 576px) {
      margin-right: -8px;
      margin-left: -8px;
    }

    + .calculator {
      @media screen and (min-width: 576px) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      @media screen and (min-width: 1024px) {
        border-radius: 4px;
      }
    }

    @media screen and (min-width: 576px) {
      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    &[hidden] {
      .product-header {
        &:after {
          margin-top: -.475em;
          transform: rotate(-45deg);
        }
      }
    }

    .product-button-link {
      box-shadow: var(--fi-button-shadow, none);
      background-color: var(--fi-primary, #ffc809);
      text-align: initial;
      position: relative;
      padding: 16px;
      border-radius: var(--fi-button-border-radius, 6px);
      color: var(--fi-button-color, #2b2e42);
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      display: flex;
      align-items: center;
      vertical-align: middle;
      justify-content: space-between;

      &:hover {
        background-color: var(--fi-primary-300, #ffde6c);
      }

      .button-text {
        white-space: nowrap;
        font-weight: bold;
      }

      .button-continue {
        svg {
          width: 15px;
          height: 24px;
          vertical-align: middle;
        }
      }
    }

    .product-header {
      padding: 1rem;
      background: var(--fi-color-main-yellow);
      position: relative;
      text-transform: uppercase;
      cursor: pointer;

      // need to show only if multiple products
      display: none;

      @media screen and (min-width: 1024px) {
        cursor: initial;
        text-transform: initial;
      }

      &:after {
        border: .125em solid var(--fi-icon-arrow);
        border-right: 0;
        border-top: 0;
        content: " ";
        display: block;
        height: .625em;
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: rotate(135deg);
        transform-origin: center;
        transition-duration: var(--bulma-duration);
        transition-property: border-color;
        width: .625em;
        border-color: var(--bulma-navbar-dropdown-arrow);
        inset-inline-end: 1.125em;
        border-bottom-width: .09em;
        border-left-width: .09em;
        margin-top: -.175em;
      }

      @media screen and (min-width: 1024px) {
        background: white;
        margin-bottom: 0;
        padding-bottom: 0;

        &:after {
          display: none;
        }
      }
    }

    .product-body {
      --tab-body-gap: 1rem;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      position: relative;
      padding: 1rem;
      background: white;

      @media screen and (min-width: 1024px) {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      .description-container {
        margin-bottom: 8px;

        ul {
          list-style-type: none;
          padding-top: 8px;

          @media screen and (max-width: 576px) {
            padding-top: 16px;
          }
          li {
            position: relative;
            padding-left: 1em;
            margin-left: 1em;
            &::before {
              content: "";
              display: inline-block;
              position: absolute;
              left: -0.5em;
              top: 0;
              transform: rotate(45deg);
              height: 1em;
              width: 0.5em;
              border-bottom: 0.15em solid #131313;
              border-right: 0.15em solid #131313;
            }
          }
        }
      }

      .fee-container {
        font-size: .75rem;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .amount-wrapper {
          display: flex;
          gap: 8px;

          .amount {
            text-align: right;
            color: #0B2634;
            font-size: 32px;
            font-family: Poppins;
            font-weight: 700;
            line-height: 48px;
            word-wrap: break-word
          }
        }
      }

      .disclaimer-container {
        .disclaimer-container-text {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14.4px;
        }
      }

      .apr-container {
        padding: 8px;
        background: rgba(255, 255, 255, 0.50);
        border-radius: 8px;
        overflow: hidden;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        display: inline-flex
      }

      @media screen and (min-width: 1024px) {
        .fee-container {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }

        .disclaimer-container {
          position: relative;

          .disclaimer-container-text {
            position: absolute;
            bottom: 0;
          }
        }
      }

      ul {
        font-size: .75rem;
      }

      footer {
        margin-top: 1rem;
        align-items: flex-end;

        .note {
          font-size: .75rem;
          line-height: .9rem;
        }
      }
    }

    .apr-container {
      display: flex;
      gap: .5rem;
      align-items: flex-start;
      padding: .5rem;
      background: rgba(255, 255, 255, 0.50);
      position: relative;

      @media screen and (min-width: 1024px) {
        margin-top: .875rem;
        border-radius: .5rem;
      }
    }
  }

  &.form {
    @media screen and (min-width: 1024px) {
      display: flex;
      position: relative;

      .bg-desktop {
        background-position: bottom left;
      }
    }

    .container-form {
      padding-bottom: 32px;
      @media screen and (max-width: 576px) {
        padding-left: 0;
        padding-right: 0;
        .heading {
          .row {
            margin: 0;
          }
        }
      }

      ul {
        padding-left: 20px;
        padding-top: 16px;

        @media screen and (max-width: 576px) {
          padding-left: 20px;
          padding-top: 8px;
        }
      }
    }

    .product-form {
      background: #fff;
      border-radius: 8px;
      padding: 32px 32px;

      @media screen and (max-width: 576px) {
        padding: 16px 8px;
      }

      .disabled-link {
        pointer-events: none;
        background-color: var(--fi-disabled-background, #fff0bb);
        box-shadow: var(--fi-button-shadow-disabled, none);
        opacity: 1;
        color: var(--fi-disabled-text, #989791);
      }

      header {
        text-align: center;

        h2 {
          margin-bottom: 16px;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 19.2px;
        }
      }

      .row .form-column{
        margin-bottom: 8px;
      }
    }
  }

  .calculator-product-list {
    color: #fff;
    border-radius: 0 !important;
    background: var(--fi-color-darkblue);

    @media screen and (min-width: 576px) {
      background: transparent;
    }

    .calculator-header-description {
      color: #FFF;
      text-align: center;

      font-size: 1.25rem;

      margin-top: 1rem;
      margin-bottom: 1.5rem;

      @media screen and (min-width: 576px) {
        color: var(--fi-color-main-dark-blue);
      }
    }

    .button-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      justify-content: center;
      justify-items: stretch;
      gap: 8px;
      margin: 0;
      padding: 0px 16px 32px 16px;
      list-style: none;

      @media screen and (min-width: 576px) {
        grid-template-columns: repeat(6, 1fr);
      }

      @media screen and (min-width: 1024px) {
        display: flex;
      }

      .button {
        grid-column: span 2;

        /* Dealing with single orphan */
        &:last-child:nth-child(2n - 1) {
          grid-column-end: -2;
        }

        @media screen and (min-width: 576px) {
          /* Dealing with 2 orphan items */
          &:last-child:nth-child(3n - 1) {
            grid-column-end: -2;
          }

          &:nth-last-child(2):nth-child(3n + 1) {
            grid-column-end: 4;
          }

          /* Dealing with single orphan */
          &:last-child:nth-child(3n - 2) {
            grid-column-end: 5;
          }
        }

        @media screen and (min-width: 1024px) {
          flex: 1;

          &:last-child:nth-child(3n - 1) {
            grid-column-end: unset;
          }

          &:nth-last-child(2):nth-child(3n + 1) {
            grid-column-end: unset;
          }

          /* Dealing with single orphan */
          &:last-child:nth-child(3n - 2) {
            grid-column-end: unset;
          }
        }

        .button-link {
          display: flex;
          height: 100%;
          padding: 8px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 8px;
          border-radius: 4px;
          background: #FFF;

          img {
            display: flex;
            width: 64px;
            height: 64px;
            padding: 5.984px 5.907px;
            justify-content: center;
            align-items: center;
          }

          .product-title {
            color: var(--dark-blue, #0B2634);
            text-align: center;

            font-size: .75rem;
          }
        }
      }
    }
  }
}

// Calculator layout depending on product fields.
.calculator-container {
  .calculator {
    .product-body {
      @media screen and (min-width: 1024px) {
        flex-direction: row;
        flex-wrap: wrap;
      }

      .description-container {
        order: 5;
      }

      .amount-slider-container {
        order: 1;
        margin-bottom: var(--tab-body-gap);
      }

      .period-slider-container {
        order: 2;
        margin-bottom: var(--tab-body-gap);
      }

      .fee-container {
        order: 3;
        margin-bottom: var(--tab-body-gap);
      }

      .disclaimer-container {
        order: 6;
      }

      .control-container {
        order: 4;
        margin-bottom: var(--tab-body-gap);
      }

      .apr-container {
        order: 7;
      }

      @media screen and (min-width: 1024px) {
        flex-direction: row;
        flex-wrap: wrap;

        .description-container {
          order: 1;
          width: calc(40% - var(--tab-body-gap));
        }

        .amount-slider-container {
          order: 2;
          width: calc(40% - var(--tab-body-gap));
        }

        .fee-container {
          order: 3;
          width: calc(20% - var(--tab-body-gap));
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }

        .disclaimer-container {
          order: 4;
          width: calc(50% - var(--tab-body-gap));
          position: relative;

          .disclaimer-container-text {
            position: absolute;
            bottom: 0;
          }
        }

        .disclaimer-spacer {
          order: 5;
          width: calc(20% - var(--tab-body-gap));
        }

        .control-container {
          order: 6;
          width: calc(30% - var(--tab-body-gap));
          margin-bottom: 0;
        }

        &.with-period-slider {
          .description-container {
            order: 1;
            width: 100%;
          }

          .amount-slider-container {
            order: 2;
            width: calc(40% - var(--tab-body-gap));
          }

          .period-slider-container {
            order: 3;
            width: calc(40% - var(--tab-body-gap));
            margin-left: var(--tab-body-gap);
          }

          .fee-container {
            order: 4;
            width: calc(20% - var(--tab-body-gap));
          }

          .disclaimer-container {
            order: 5;
            width: calc(50% - var(--tab-body-gap));
          }

          .disclaimer-spacer {
            order: 6;
            width: calc(20% - var(--tab-body-gap));
          }

          .control-container {
            order: 7;
            width: calc(30% - var(--tab-body-gap));
          }
        }
      }
    }

    &.mobile-no-description {
      @media screen and (max-width: 1024px) {
        .description-container {
          display: none;
        }
      }
    }

    &.no-fee {
      .fee-container {
        display: none;
      }

      .product-body {
        @media screen and (min-width: 1024px) {
          .description-container {
            width: calc(50% - var(--tab-body-gap));
          }

          .amount-slider-container {
            width: calc(50% - var(--tab-body-gap));
          }

          &.with-period-slider {
            .description-container {
              width: 100%;
            }

            .amount-slider-container {
              width: calc(50% - var(--tab-body-gap));
            }

            .period-slider-container {
              width: calc(50% - var(--tab-body-gap));
            }
          }
        }
      }
    }
  }
}
