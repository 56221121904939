// Load Bulma's base styles and themes (including the minireset)
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/components/navbar.sass";

:root {
	--bulma-navbar-height: 56px;
	--bulma-navbar-background-color: #fff;
}

@media screen and (min-width: 1024px) {
  html.has-navbar-fixed-top, body.has-navbar-fixed-top {
    padding-top: 120px;
  }
}

.navbar {
	--bulma-burger-h: 0;
	--bulma-burger-s: 0%;
	--bulma-burger-l: 0%;
	--bulma-body-color: #000;
	--bulma-arrow-color: var(--fi-icon-arrow);
	--bulma-navbar-height: 56px;
	--bulma-navbar-dropdown-arrow: var(--fi-icon-arrow);
	--bulma-navbar-dropdown-background-color: white;
	--bulma-navbar-dropdown-boxed-shadow: 0px 12px 20px rgba(195.49, 117.30, 0, 0.20);
	--bulma-navbar-padding-vertical: 2rem;
	--bulma-navbar-padding-horizontal: 5rem;

	--bulma-burger-border-radius: 0.5em;
	--bulma-burger-gap: 5px;
	--bulma-burger-item-height: 2px;
	--bulma-burger-item-width: 20px;

  position: absolute !important;
	font-size: 0.875rem;
  border-bottom: 1px solid var(--fi-color-yellow);
  background-color: var(--bulma-navbar-background-color);

	.link-upper {
		text-transform: uppercase;
	}

  @media screen and (min-width: 1024px) {
    &.is-spaced {
      padding: 2rem 5rem;
    }
  }

  .navbar-dropdown {
    &.long-list {
      display: grid !important;
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr;
    }

    a {
      &.navbar-item {
        &:hover {
          background-color: var(--fi-color-light-yellow)!important;
        }
      }
    }
  }

  &.logged-in {
    #login-button {
      display: none;
    }

    #profile-button {
      display: block;

      & img {
        padding-right: 8px;
      }
    }
    .dark {
      #profile-button{
        & img {
          filter: none;
        }
        &:hover img {
          filter: #{"invert()"};
        }
      }
    }
  }
}

.navbar-link:not(.is-arrowless) {
	&::after {
		border-bottom-width: 0.09em;
		border-left-width: 0.09em;
		margin-top: -.475em;
	}
}

.navbar-end {
  margin: 16px;
}

.navbar-item {
	font-weight: 600;

	.header-logo {
		height: 28px;
		max-height: 28px;
	}
	.navbar-button {
		display: flex;

		.button-small {
			height: 48px;
		}
	}
	&.has-button {
		padding: 0;
	}
}

a {
  &.navbar-item {
    &:hover {
      background-color: var(--fi-color-light-yellow)!important;
    }
  }
}

.navbar-item, .navbar-link {
	padding: .5rem 1rem;
}

@media screen and (min-width: 1024px) {
	:root {
		--bulma-navbar-height: 120px;
		--bulma-navbar-background-color: rgba(255, 255, 255, 0.60);
	}
	.navbar-end {
		gap: 16px;
    margin: 0 0 0 auto;
	}
	.navbar-brand {
		.navbar-item {
			padding: 0;
			.header-logo {
				height: 56px;
				max-height: 56px;
			}
		}
	}
  .navbar.is-spaced .navbar-dropdown, .navbar-dropdown.is-boxed {
    border-radius: 0;
  }
}

#profile-button {
  display: none;
}
