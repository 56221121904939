.cookie-consent {
  display: none;
  width: 100%;
  position: fixed;
  bottom: 0;
  box-shadow: 0 0.5px 0 0 #d1d6df, 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 8px;
  z-index: 1000;
  border-top: 1px #d1d6df solid;
  box-sizing: border-box;
  transform: translateX(-100%);

  p {
    font-size: 10px;
    text-align: justify;
  }

  .cookie-consent-button {
    padding: 8px;
    background: #dcecf3;
    border-radius: var(--fi-button-border-radius, 6px);
    text-align: center;
    font-size: 10px;
    cursor: pointer;
  }
}

.slide-in {
  animation: slide-in 0.5s forwards;
  animation-delay: 1s;
}

.slide-out {
  animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
