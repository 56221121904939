.products {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  display: flex;
  margin: auto;

  @media screen and (min-width: 1024px) {
    gap: 3.5rem;
  }

  .product-links {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;

    list-style-type: none;

    @media screen and (min-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    }

    .product-link-wrapper {

      .product-link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        gap: .5rem;
        border-radius: 4px;
        background: #FFF;
        height: 100%;
        padding: 8px 16px 8px 8px;

        .product-title {
          display: flex;
          flex-direction: column;
          flex: 1;
        }

        .icon-continue {
          display: flex;
          width: 40px;
          height: 40px;
          padding: 8px;
          justify-content: center;
          align-items: center;

          border-radius: 50%;
          background: var(--Finzmo-yellow, #FFC809);
        }
      }
    }
  }
}
