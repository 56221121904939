.partners {
	padding: 80px 0;
	header {
		text-align: center;
	}
	.partner-list {
    margin: 56px 0;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    display: inline-flex;
    flex-wrap: wrap;

		@media screen and (min-width: 1024px) {
			margin: 56px 0;
			justify-content: center;
			align-items: flex-start;
			gap: 1rem;
			display: inline-flex;
			flex-wrap: wrap;
		}

		li {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: .875rem;
      width: 128px;

			@media screen and (min-width: 1024px) {
				width: 276px;
				height: 112px;
				padding: 19px;
			}

      img {
        width: 100%;
      }
		}
	}
	footer {
		text-align: center;
	}
}
