@import "colors";
//@import "~bulma/sass/base/_all.sass";
@import "~bulma/sass/helpers/spacing";
@import "~bulma/sass/helpers/visibility";
@import "flexboxgrid2.min.css";

// Responsive breakpoints: (mobile portrait is default)
// -- mobile landscape: min-width: 480px;
// -- tablet portrait: min-width: 768px;
// -- tablet landscape, Laptop, Desktop: min-width: 992px;
// -- large desktop, tv: min-width: 1200px;

:root {
  --fi-bg-primary: #F5F1EB;
  --fi-bg-secondary: #ECECEC;

  --fi-text-color: #131313;

  --fi-color-main-dark-orange: #FF7A00;
  --fi-color-main-orange: #FFA300;
  --fi-color-main-yellow: #FFC809;
  --fi-color-main-deep-blue: #134565;
  --fi-color-main-dark-blue: #0B2634;

  --fi-color-theme-one: #C60063;
  --fi-color-theme-orange: #FF6700;
  --fi-color-theme-blue: #015BAF;
  --fi-color-theme-green: #3E6D31;
  --fi-color-theme-yellow: #FFC809;


  --fi-color-light-yellow: #FFF1C1;
  --fi-color-yellow: #FFC809;
  --fi-color-darkblue: #0B2634;
  --fi-icon-arrow: #2A2A2A;
  --fi-well-testimonials: #dcecf3;
  --fi-well-partners: #f6fafb;



  --fi-primary: #{$primary};
  --fi-primary-300: #{$primary300};
  --fi-secondary: #{$secondary};
  --fi-secondary-900: #{$primary};
  --fi-secondary-700: #{$secondary700};
  --fi-secondary-600: #{$secondary600};
  --fi-secondary-500: #{$secondary500};
  --fi-secondary-400: #{$secondary400};
  --fi-secondary-200: #{$secondary200};
  --fi-secondary-100: #{$secondary100};
  --fi-error: #{$errortint};
  --fi-button-width: 335px;
  --fi-button-margin: 10px;
  --fi-button-border-radius: 1.5rem;
  --fi-blog-product-bg: #{$secondary900};

  --fi-slider-value-font-size: 36px;
  --fi-slider-thumb-border-radius: 0;
  --fi-slider-control-border-radius: 0;

  --fi-footer-bg-color: #1a1c2b;
  --fi-footer-text-highlight: #{$color-white};
  --fi-footer-text-color: #{$color-steel};
  --fi-hero-text-color: #{$secondary};

  --fi-well-facts-bg: #ffc80a;

  --fi-well-how-it-works-bg: #ffc80a;

  --fi-well-partners-bg: #2b2e42;
  --fi-well-partners-text-color: #fff;

  --fi-well-testimonials-bg: #2b2e42;
  --fi-well-testimonials-text-color: #fff;

  --fi-well-advantages-bg: #2b2e42;
  --fi-well-advantages-text-color: #fff;

  --fi-process-step-background: #fff;
}

*, *::before, *::after {
  box-sizing: inherit;
}

html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--fi-color-main-dark-blue);
  line-height: 120%;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

h1 {
  font-size: 1.25rem;
}

h2 {
  font-size: 1rem;
}

h3 {
  font-size: .875rem;
}

h4, h5, h6 {
  font-size: .625rem;
}

a {
  color: inherit;
  text-decoration: none;
}

nav {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

label {
  font-size: .75rem;
  line-height: 120%;
}

.text-style-p1, .text-style-p2 {
  line-height: 130%;
}

.text-style-p3, .text-style-p4 {
  line-height: 120%;
}

.text-style-p1 {
  font-size: 1rem;
}

.text-style-p2 {
  font-size: .875rem;
}

.text-style-p3 {
  font-size: .75rem;
}

.text-style-p4 {
  font-size: .625rem;
}

@media screen and (min-width: 1024px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1rem;
  }

  h4, h5, h6 {
    font-size: .75rem;
  }

  .text-style-p1, .text-style-p2, .text-style-p3, .text-style-p4 {
    line-height: 150%;
  }
}

body {
  --text-color: #fff;

  background: var(--fi-bg-primary);
  color: var(--fi-text-color);
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;

  > footer {
    margin-top: 70px;
    border-top: 1px var(--fi-color-main-yellow) solid;

    .navigation {
      color: var(--text-color);
      background: var(--fi-color-darkblue);
      padding: 4rem;

      font-size: 10px;
      font-weight: 700;
      line-height: 15px;
      word-wrap: break-word;

      li, p {
        color: var(--text-color);
      }
    }

    nav {
      > ul {
        display: flex;
        flex-direction: row;
        gap: 3rem;

        .header {
          color: var(--text-color);
          font-size: 24px;
          line-height: 28.80px;
          word-wrap: break-word;
          margin-bottom: 2rem;
        }

        ul {
          display: flex;
          flex-direction: column;
          gap: 8px;

          a {
            color: var(--text-color);
            text-transform: uppercase;
          }
        }
      }
    }

    .copyright {
      font-size: .875rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      padding: 4rem;

      a {
        display: flex;
        align-items: center;
      }

      .icon {
        display: inline-flex;
        min-width: 32px;
        height: 32px;
        padding: 8px;
        background: var(--fi-color-main-dark-blue);
        border-radius: 100%;
        margin-right: 8px;

        img {
          height: auto;
          max-width: 100%;
        }
      }
    }

    .terms {
      margin-top: 4rem;

      a {
        text-decoration: underline;
      }
    }
  }
}
