@import "colors";
@import "fonts";
@import "_components/advertisers";
@import "_components/blog-posts";
@import "_components/calculator";
@import "_components/chips";
@import "_components/content-block";
@import "_components/cookie-consent";
@import "_components/credit-card";
@import "_components/examples";
@import "_components/footer";
@import "_components/introduction";
@import "_components/keypoints";
@import "_components/navigation";
@import "_components/pagination";
@import "_components/partners";
@import "_components/process";
@import "_components/products";
@import "_components/rating";
@import "_components/reviews";
@import "_components/tabs";
@import "_components/user";
@import "blog";

html,
body {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  scroll-behavior: smooth;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;
}

.bg-yellow {
  background-image: url('data:image/svg+xml,<svg width="828" height="597" viewBox="0 0 828 597" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_f_1108_8740)"><circle cx="822" cy="66" r="255" fill="%23FFC809"/></g><g filter="url(%23filter1_f_1108_8740)"><circle cx="522" cy="-24" r="246" fill="%23FFC809"/></g><defs><filter id="filter0_f_1108_8740" x="291" y="-465" width="1062" height="1062" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="138" result="effect1_foregroundBlur_1108_8740"/></filter><filter id="filter1_f_1108_8740" x="0" y="-546" width="1044" height="1044" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="138" result="effect1_foregroundBlur_1108_8740"/></filter></defs></svg>');
  background-repeat: no-repeat;
  background-position: top left;

  @media screen and (min-width: 576px) {
    background-position: top right;
  }
}

.bg-blue {
  background-image: url('data:image/svg+xml,<svg width="828" height="597" viewBox="0 0 828 597" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_f_1108_8740)"><circle cx="822" cy="66" r="255" fill="%23015BB0"/></g><g filter="url(%23filter1_f_1108_8740)"><circle cx="522" cy="-24" r="246" fill="%23015BB0"/></g><defs><filter id="filter0_f_1108_8740" x="291" y="-465" width="1062" height="1062" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="138" result="effect1_foregroundBlur_1108_8740"/></filter><filter id="filter1_f_1108_8740" x="0" y="-546" width="1044" height="1044" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="138" result="effect1_foregroundBlur_1108_8740"/></filter></defs></svg>');
  background-repeat: no-repeat;
  background-position: top center;

  @media screen and (min-width: 576px) {
    background-position: top right;
  }
}

.bg-orange {
  background-image: url('data:image/svg+xml,<svg width="828" height="597" viewBox="0 0 828 597" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_f_1108_8740)"><circle cx="822" cy="66" r="255" fill="%23FF6700"/></g><g filter="url(%23filter1_f_1108_8740)"><circle cx="522" cy="-24" r="246" fill="%23FF6700"/></g><defs><filter id="filter0_f_1108_8740" x="291" y="-465" width="1062" height="1062" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="138" result="effect1_foregroundBlur_1108_8740"/></filter><filter id="filter1_f_1108_8740" x="0" y="-546" width="1044" height="1044" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="138" result="effect1_foregroundBlur_1108_8740"/></filter></defs></svg>');
  background-repeat: no-repeat;
  background-position: top center;

  @media screen and (min-width: 576px) {
    background-position: top right;
  }
}

.bg-green {
  background-image: url('data:image/svg+xml,<svg width="828" height="597" viewBox="0 0 828 597" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_f_1108_8740)"><circle cx="822" cy="66" r="255" fill="%233E6D31"/></g><g filter="url(%23filter1_f_1108_8740)"><circle cx="522" cy="-24" r="246" fill="%233E6D31"/></g><defs><filter id="filter0_f_1108_8740" x="291" y="-465" width="1062" height="1062" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="138" result="effect1_foregroundBlur_1108_8740"/></filter><filter id="filter1_f_1108_8740" x="0" y="-546" width="1044" height="1044" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="138" result="effect1_foregroundBlur_1108_8740"/></filter></defs></svg>');
  background-repeat: no-repeat;
  background-position: top center;

  @media screen and (min-width: 576px) {
    background-position: top right;
  }
}

.well {
  &.white {
    background: white;
  }
  &.dark {
    background: var(--fi-color-darkblue, #0B2634);

    header {
      color: white;
      h1 {
        color: inherit;
      }
    }
  }
}

.btn {
  &.btn-link {
    display: flex;
    align-items: center;
    gap: .5rem
  }
}

.content-container {
  padding-top: 2rem;
  padding-bottom: 2rem;

  &.common-container,
  &.advertisers-container,
  &.contact-container,
  &.profile-container,
  &.login-container,
  &.credit-card-comparison-container {
    background: white;
  }

  &.about-us-container {
    .content-wrapper + .content-wrapper {
      margin-top: 2rem;
    }
  }

  &.process-container {
    background: white;
    padding: 0 0 2rem 0;

    .calculator-service {
      padding: 0;
    }
  }

  ul {
    list-style: disc;
    padding-inline-start: 1rem;

    li {
      margin-bottom: .25rem;
    }
  }

  img {
    max-width: 100%;
    vertical-align: middle;
  }
}

.checkbox-list {
  list-style-type: none;
  li {
    position: relative;
    padding-left: 1em;
    margin-left: 1em;
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      left: -0.5em;
      top: 0;
      transform: rotate(45deg);
      height: 1em;
      width: 0.5em;
      border-bottom: 0.15em solid #131313;
      border-right: 0.15em solid #131313;
    }
  }
}

.cta-button {
  margin: 16px 0;

  .sc-fi-button {
    white-space: normal;
  }
}

.form-row {
  margin-top: 10px;
}

fi-button {
  svg, img {
    vertical-align: middle;
  }
  .button-content {
    .button-text {
      align-items: center;
    }
  }
  .secondary {
    --fi-secondary-900: #fff;
  }
}

fi-checkbox {
  .checkbox-container {
    &.sc-fi-checkbox {
      input {
        &.sc-fi-checkbox {
          &:checked ~ .checkmark {
            &.sc-fi-checkbox {
              border-color: var(--fi-color-main-dark-blue);
              background-color: var(--fi-color-main-dark-blue);
            }
          }
        }
      }
      .checkmark {
        &.sc-fi-checkbox {
          top: 3px;
          border-color: var(--fi-color-main-dark-blue);

          &:after {
            border-width: 0 2px 2px 0;
            border-color: #fff;
          }
        }
      }
    }
  }
  &[disabled] {
    .checkbox-container {
      &.sc-fi-checkbox {
        input {
          &.sc-fi-checkbox {
            &:checked ~ .checkmark {
              &.sc-fi-checkbox {
                border-color: #B6BABF;
                background-color: #B6BABF;
              }
            }
          }
        }
        .checkmark {
          border-color: #B6BABF;
        }
      }
    }
  }
}

fi-switch {
  --fi-primary: var(--fi-color-main-deep-blue);
}

fi-range-slider {
  --fi-slider-control-color: #000;
  --fi-slider-control-bg-color: #ffc809;
  --fi-slider-control-size: 40px;
  --fi-slider-control-thickness: 1px;
  --fi-slider-control-border-radius: 50%;
  --fi-slider-control-margin: 10px;
  --fi-slider-track-height: 4px;
  --fi-slider-thumb-size: 32px;
  --fi-slider-thumb-margin: -14px 0 0 0;
  --fi-slider-thumb-border-size: 8px;
  --fi-slider-thumb-border-radius: 50%;
  --fi-slider-thumb-border-color: #134565;
  --fi-slider-thumb-color: #0B2634;
  --fi-slider-input-font-weight: 600;
  --fi-slider-input-size-offset: 12;
  --fi-slider-value-font-size: 1.5rem;
  --fi-slider-affix-font-size: 1rem;
  --fi-slider-affix-color: #0B2634;
  --fi-primary: #134565;

  &::part(input) {
    display: flex;
    align-items: center;
  }

  &::part(input-label) {
    flex: 1;
    font-weight: 600;
  }

  &::part(input-field) {
    width: auto;
  }

  //&::part(value) {
  //  font-size: .875rem;
  //  align-items: center;
  //  flex-direction: row;
  //  justify-content: space-between;
  //}
  //&::part(range) {
  //  display: grid;
  //  grid-template-columns: repeat(2, 1fr);
  //}
  //&::part(slider) {
  //  grid-area: 1 / 1 / 2 / 3;
  //  margin-left: 0;
  //  margin-right: 0;
  //  margin-bottom: 1rem;
  //}
}

fi-input {
  .fi-input.sc-fi-input {
    padding-top: 0;

    .input-group.sc-fi-input {
      border: 1px #848484 solid;
      border-radius: 4px;

      [slot="prefix"] {
        display: flex;
        align-items: center;
        padding-left: 1rem;

        + .input-container.sc-fi-input {
          .input-control.sc-fi-input {
            padding-left: 8px;
          }
        }
      }

      .input-container.sc-fi-input {
        label.sc-fi-input {
          &.label {
            top: -20px;
            bottom: unset;
          }
          &.placeholder {
            left: 17px;
            bottom: 19px;
          }
        }
        .input-control.sc-fi-input {
          padding: 16px 8px 16px 16px;
          border: 0;

          input.sc-fi-input {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }
        }

        &::after {
          display: none;
        }
      }
    }

    &.visited {
      .input-group.sc-fi-input {
        .input-container.sc-fi-input {
          .input-control.sc-fi-input {
            border-color: #212121;
          }
        }
      }
    }

    &:has(input:focus) {
      .input-group.sc-fi-input {
        border-color: #0038FF;
        box-shadow: 0 0 5px #000BFF;

        .input-container.sc-fi-input {
          label.sc-fi-input {
            &.placeholder {
              top: -20px;
              left: 0;
              bottom: unset;
              line-height: 120%;
            }
          }
          .input-control.sc-fi-input {

          }
        }
      }
    }
  }
}

fi-date {
  .sc-fi-date {
    &.label {
      top: -20px !important;
    }

    &.select-container-day,
    &.select-container-month {
      max-width: 21% !important;
    }

    &.select-container-year {
      max-width: 28% !important;
    }
  }
}

fi-select {
  .sc-fi-select {
    &.dropdown {
      padding-top: 0 !important;

      &.dropdown-active {
        border: none;
        margin-left: 0 !important;
        margin-top: 0;
        padding: 0 !important;
        box-sizing: border-box;

        .sc-fi-select {
          &.button {
            border-color: #0038FF;
            box-shadow: 0 0 5px #000BFF;

            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom-color: var(--fi-secondary-600, #888d96);

            .sc-fi-select {
              &.icon {
                top: unset !important;
                bottom: unset !important;
                right: unset !important;
              }
            }
          }
        }
      }
      .sc-fi-select {
        &.label {
          position: absolute;
          top: -20px;
        }

        &.button {
          border: 1px #848484 solid;
          border-radius: 4px;
          //padding: 16px 8px 16px 16px;
          padding: 12px 8px 12px 16px;
          width: 100% !important;
          display: flex;
          align-items: center;

          .sc-fi-select {
            &.icon {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 30px;
              height: 30px;
              top: unset !important;
              bottom: unset !important;
              right: unset !important;
            }
          }

          .sc-fi-select {
            &.button-text {
              //color: var(--fi-secondary-400, #8b99ae);
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
              padding: 0;
            }
          }
        }
        &.underline {
          display: none;
        }
        &.dropdown-menu {
          margin-left: 0 !important;
          border-color: #0038FF;
          border-top-color: var(--fi-secondary-600, #888d96);

          &.shadow {
            box-shadow: inset 0 -10px 7px -10px #888d96, 0 1px 5px #000BFF !important;
          }

          .sc-fi-select {
            &.dropdown-item {
              padding: 8px 8px 8px 16px !important;
            }
          }
        }
      }
    }
  }
}

fi-autocomplete {
  .autocomplete {
    .input {
      .input-wrapper {
        display: flex;
        align-items: center;
        margin-left: 0;
        padding: 12px 8px 12px 16px;
        border: 1px #848484 solid;
        border-radius: 4px;

        label {
          position: absolute;
          top: -20px;
          left: 0;
        }

        .autocomplete-input {
          padding: 0;
          border-bottom: 0;
          height: auto;
        }

        .icon {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          top: unset !important;
          bottom: unset !important;
          right: unset !important;
        }
      }
      &.fi-active {
        .input-wrapper {
          margin-top: 0;
          margin-left: 0 !important;
          box-sizing: border-box;

          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-color: #0038FF;
          box-shadow: 0 0 5px #000BFF;
        }
      }
    }
    .suggestions {
      width: 100%;
      margin-left: 0;
      border-color: #0038FF;
      box-shadow: 0 1px 5px #000BFF !important;

      .suggestion {
        padding: 10px 16px;
      }
    }
  }
}

fi-toggle-button {
  --fi-button-border-radius: 4px;
  --fi-secondary-600: #848484;

  .sc-fi-toggle-button {
    &.toggle-buttons-container {
      .sc-fi-toggle-button {
        &.toggle-buttons {
          .sc-fi-toggle-button {
            &.toggle-button {
              padding: 1rem;

              &.selected {
                background: var(--fi-primary, #ffc809);
                border-color: var(--fi-primary, #ffc809) !important;
              }
            }
          }
        }
      }
    }
  }
}

fi-radio-button {
  --fi-button-border-radius: 4px;
  --fi-secondary-600: #848484;

  .sc-fi-radio-button {
    &.radio-buttons-container {
      .sc-fi-radio-button {
        &.label {
          font-size: 12px;
        }
        &.radio-buttons {
          .sc-fi-radio-button {
            &.radio-button {
              border-radius: 0;
              margin-right: 0;

              &.selected {
                background: var(--fi-primary, #ffc809);
                border-color: var(--fi-primary, #ffc809);
              }

              &.radio-button-small {
                padding: 1rem;
              }

              &:first-child {
                border-top-left-radius: var(--fi-button-border-radius, 6px);
                border-bottom-left-radius: var(--fi-button-border-radius, 6px);
              }

              &:first-child:nth-last-child(2) {
                border-right: solid 1px var(--fi-secondary-600, #fafafa);
              }

              &:not(:last-child) {
                border-right: none;
              }

              &:last-child {
                border-top-right-radius: var(--fi-button-border-radius, 6px);
                border-bottom-right-radius: var(--fi-button-border-radius, 6px);
              }
            }
          }
        }
      }
    }
  }
}

fi-button-collection {
  --fi-button-border-radius: 0.25rem;

  .buttons-container {
    &.sc-fi-button-collection {
      .button-container {
        &.sc-fi-button-collection {
          margin: 0 14px 0.5rem;
          box-shadow: none;

          .button-continue {
            border-radius: 50%;
            background: var(--fi-color-yellow);

            svg {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }
  }
}

.progress-bar {
  margin-top: 0!important;
}

fi-progressbar {
  &::part(bar) {
    height: 16px;
    background: white;
  }
  &::part(fill) {
    height: 16px;
    border-radius: 0;
    margin-top: 0;
  }
}

.homepage {
  > section + section,
  > .well + section,
  > section + .well {
    margin-top: 2rem;

    @media screen and (min-width: 1024px) {
      margin-top: 3rem;
    }
  }
}

.name-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}
