.tabs-section {
	position: relative;
	justify-content: center;
	display: flex;
	flex-wrap: wrap;

	.tab-item {
		display: contents;
		&[open] {
			& > .details-tab {
				background: #fff;
				color: #333;
			}
		}

		.tab-header {
			padding: 0.5rem 1rem;
			font-size: 1.1rem;
			color: #ffffffcc;
			font-weight: 600;
			display: block;
			order: 0; // tabs come first
			background: #ffffff13;
			border-radius: 1.5rem;
			margin-right: 0.35rem;
			margin-bottom: 0.5rem;
			cursor: pointer;
			border: 2px solid transparent;
			&:hover, &:focus {
				border-color: #fff;
			}
			&::-webkit-details-marker {
				display: none; // hide the default arrow icon
			}
		}
		.tab-content {
			order: 1; // content comes after tabs
			padding: 1rem;
			width: 100%; // make the content sit on it's own row
			details {
				summary {
					font-weight: 600;
				}
				margin: 1.5rem;
			}
		}
	}
}

.tab-bar {
	width: 100%;
	height: 100%;
	background: white;
	border-radius: 32px;
	justify-content: center;
	align-items: center;
	gap: 40px;
	display: inline-flex;
	margin-bottom: 8px;

	.tab {
		justify-content: center;
		align-items: center;
		gap: 8px;
		display: flex;

		.tab-link {
			color: #0B2634;
			font-size: 16px;
			font-family: Poppins;
			font-weight: 600;
			text-transform: uppercase;
			line-height: 16px;
			word-wrap: break-word;
      padding: 8px 16px;
      border-radius: 40px;
      border: 0;
      background: transparent;
      cursor: pointer;

			&:hover, &[aria-selected=true] {
				background: #FFC809;
			}
		}
	}
}

.tab-content {
	&[hidden] {
		.tab-body {
			display: none;
		}
	}
	@media screen and (min-width: 1024px) {
		&[hidden] {
			display: none;
		}
	}
}
