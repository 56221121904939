.keypoints {
  padding: 0 8px;

	header {
		text-align: center;
		margin-bottom: 56px;
	}

	.keypoint-list {
		display: inline-flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: space-evenly;
    gap: 40px;

    @media screen and (max-width: 576px) {
      gap: 32px 0;
    }

		.keypoint {
      @media screen and (max-width: 576px) {
        width: 134px;
      }

			width: 158px;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			gap: 8px;
			display: inline-flex;
			text-align: center;

			.icon {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 96px;
				height: 96px;
				border-radius: 50%;
				background: #FFC809;
			}
		}
	}
}
