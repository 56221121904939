.examples {
	padding: 48px 0;
  overflow: hidden;
  font-size: 0.75rem;

  @media screen and (max-width: 576px) {
    margin: 0 8px;
  }

  strong {
    font-size: 1rem;
    font-weight: 600;
  }

	header {
		text-align: center;
		margin-bottom: 40px;
		h1 {
			margin-bottom: 24px;
		}
	}
  .example-wrapper {
    position: relative;

    .example-list {
      display: flex;
      gap: 0;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .example {
        background: #F5F1EB;
        border-radius: 16px;
        overflow: hidden;
        width: 100%;

        .amount {

        }

        .example-offer {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          padding: 8px 0;
          gap: 8px;

          .example-offer-customer {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
          }

          .example-offer-request {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;
          }

          .divider {
            margin: auto;
            width: 100%;
            border: 1px rgba(0, 0, 0, 0.10) solid;
          }

          .example-offer-rate {
            justify-content: space-between;
            align-items: center;
            display: flex;
            gap: 16px;
            text-align: center;

            .example-offer-rate-market {
              flex: 1;
              flex-direction: column;
              align-items: center;
              gap: 4px;
              display: flex;
              padding: 4px;
            }

            .example-offer-rate-our {
              flex: 1;
              padding: 4px;
              background: #FFC809;
              flex-direction: column;
              align-items: center;
              gap: 4px;
              display: flex;
            }
          }
        }

        .example-savings {
          width: 100%;
          padding: 8px 32px;
          background: #FFC809;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 4px;
          display: inline-flex;
        }
      }
    }

    .list-controls {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      margin-top: 2rem;

      .list-control {
        display: flex;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 0;
        background: var(--fi-color-yellow, #FFC809);
        border: none;
      }

      .list-state {
        color: #FFF;

        /* label */
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
      }
    }
  }
}
