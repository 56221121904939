footer {
  .navigation-footer-row {
    margin-left: 0;
    margin-right: 0;
  }

  .navigation,
  .navigation-footer {
    @media screen and (max-width: 576px) {
      padding: 24px !important;
    }

    nav {
      @media screen and (max-width: 576px) {
        padding: 0 !important;
      }

      ul {
        padding: 8px 8px 8px 0;

        @media screen and (max-width: 576px) {
          display: block;
          padding-top: 8px;
        }

        li {
          h4 {
            text-transform: none;
            font-size: 16px;
            font-weight: 300;
            line-height: 19.2px;
            margin-bottom: 24px;
          }

          a {
            font-size: 14px;
            font-weight: 600;
            line-height: 14px;
            text-transform: uppercase;
          }

          li {
            cursor: pointer;
          }

          @media screen and (max-width: 576px) {
            color: #FFF;
            padding: 8px;

            h4 {
              text-transform: none;
              font-size: 16px;
              font-weight: 300;
              line-height: 19.2px;
              margin-bottom: 0;
            }

            a {
              font-size: 16px;
              font-weight: 600;
              line-height: 16px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  .terms {
    @media screen and (max-width: 576px) {
      margin-top: 0 !important;
      padding: 16px;
    }
  }

  .copyright {
    @media screen and (max-width: 576px) {
      padding: 34px !important;
    }
  }

}
