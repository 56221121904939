.pagination {
  --var-link-color: rgba(19.45, 68.83, 100.93, 0.40);

  .pagination-list {
    display: inline-flex;
    align-items: center;
    gap: .875rem;
    list-style: none;

    .is-current {
      a {
        color: var(--fi-color-main-dark-blue);
      }
    }

    .pagination-link {
      display: flex;
      text-decoration: none;
      font-size: 16px;
      font-weight: bold;
      color: var(--var-link-color);

      &:hover {
        color: var(--fi-color-main-dark-blue);
      }
    }
  }

  .next-prev-button-container {
    display: flex;
    justify-content: center;
  }

  .next-prev-button {

  }

}
