.advertisers {
  border-radius: 6px;
  box-shadow: 0 20px 15px -15px rgba(196, 204, 217, 0.6);
  border: solid 1px #c4ccd9;
  background-color: #ffffff;
  margin-bottom: 20px;

  .advertiser {
    &-logo {
      background-color: #f6fafb;
      padding: 45px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      border-right: solid 1px #c4ccd9;

      @media only screen and (max-width: 576px) {
        border-top-right-radius: 6px;
        border-right: none;
        border-bottom: solid 1px #c4ccd9;
        padding: 40px;
        img {
          margin-bottom: 0 !important;
        }
      }
    }

    &-detail {
      &-amount {
        padding-left: 30px;
        @media only screen and (max-width: 576px) {
          padding-top: 20px;
        }
      }
      &-period {
        @media only screen and (max-width: 576px) {
          padding-top: 20px;
        }
      }


      &-header {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $secondary;
        text-align: left;
      }

      &-text {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: $secondary;
        text-align: left;
      }
    }

    &-button {
      padding: 30px 30px 30px 0;
      @media only screen and (max-width: 576px) {
        padding: 20px;
      }
    }
  }
}


