.reviews {
  --shadow-offset: 3rem;

  overflow: hidden;
  background: var(--fi-bg-primary);

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &.container-fluid {
    @media screen and (min-width: 992px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .review-highlight {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--fi-bg-primary);
    z-index: 1;
    padding-left: 8.333%;
    padding-top: 1rem;
    padding-right: var(--shadow-offset);

    @media screen and (max-width: 576px) {
      align-items: center;
      text-align: center;
      padding-left: 0;
      padding-right: 0;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -1rem;
    }

    .rating {
      font-size: 1.8rem;
      letter-spacing: 8px;
    }
  }
  .reviews-wrapper {
    position: relative;
    padding: 2rem 0 0.625rem 2rem;

    @media screen and (max-width: 576px) {
      padding: 2rem 0 0.625rem 0;
    }

    @media screen and (min-width: 992px) {
      &:before {
        display: block;
        width: 6rem;
        height: 100%;
        content: ' ';
        position: absolute;
        top: 0;
        left: calc(-1 * var(--shadow-offset));
        z-index: 10;

        -webkit-mask-image: linear-gradient(90deg, var(--fi-bg-primary), transparent);
        mask-image: linear-gradient(90deg, var(--fi-bg-primary), transparentize(#F5F1EB, 0.1), transparent);
        background-color: var(--fi-bg-primary);
      }
    }
  }
  .reviews-container {
    display: flex;
    gap: 1rem;
  }

  .blockquote-li {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 16px;
    background: white;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    min-width: 200px;

    @media screen and (min-width: 992px) {
      min-width: 342px;
      max-width: 374px;
    }

    blockquote {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      height: 100%;

      h3 {
        font-size: 1rem;
      }
    }
  }

  .badges {
    display: flex;
    grid-template-columns: repeat(auto-fit, minmax(179px, 1fr));
    align-items: center;
    justify-items: center;
    gap: 1rem;

    @media screen and (min-width: 992px) {
      display: grid;
    }

    li {
      display: flex;
      justify-content: center;

      img {
        width: 100%;
      }
    }
  }
}
