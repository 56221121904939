.introduction {
  padding: 65px;

  @media only screen and (max-width: 576px) {
    padding: 30px;
  }

  &-titles {
    text-align: center;
    margin: auto;

    h1 {
      font-size: 48px;
      font-weight: bold;
      margin: 0;
      @media only screen and (max-width: 576px) {
        font-size: 36px;
        line-height: 1.22;
      }
    }
  }
}
