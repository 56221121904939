.content-container {
  &.blog-post-container {
    padding-top: 1rem;
    background: white;

    @media screen and (min-width: 768px) {
      padding: 4rem 2rem;
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin: auto;
      max-width: 567px;

      @media screen and (min-width: 768px) {
        gap: 2rem;
      }

      .content-controls {
        text-transform: uppercase;
      }
    }

    .blog-post-placeholder {
      min-height: 350px;
    }

    header {
      display: flex;
      flex-direction: column;
      gap: .5rem;
    }

    .content-block {
      h2 {
        margin-top: 2rem;
        margin-bottom: 1rem;
      }
      ul {
        margin: 1rem 0;
      }
    }
  }

  .chips-date-container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}


