div#wave {
  text-align: center;
  margin: auto;
  padding: 20px;
  .dot {
    display:inline-block;
    width:12px;
    height:12px;
    border-radius:50%;
    margin-right:3px;
    background:#303131;
    animation: wave 1.3s linear infinite;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
    &:nth-child(4) {
      animation-delay: -0.7s;
    }
  }
}

@keyframes wave {
  0%, 60%, 100% {
    transform: initial;
    height: 12px;
  }

  30% {
    transform: translateY(-15px);
  }
  50% {
    height: 8px;
  }
}
