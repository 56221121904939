.block {
  .table-of-contents {
    text-align: left;

    ul {
      padding-left: 0;
      list-style: none;
    }

    li {
      position: relative;
    }

    li a {
      font-size: 18px;
      padding: 13px 15px;
      cursor: pointer;
      text-decoration: none;
      margin: 0;
      display: block;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }

    li a:hover {
      font-weight: bold;
      background: $primary400;
    }

    ul li:before {
      display: none;
    }
  }

  .content-block {
    table {
      table-layout: fixed;
      width: 100%;
      overflow-wrap: break-word;
      border: 1px solid;
      border-collapse: collapse;

      th {
        border: 1px solid;
        font-weight: bold;
        text-align: center;
      }

      td {
        border: 1px solid;
        text-align: start;
      }
    }
  }

  .block-headline {
    font-size: 2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $secondary;
    margin: 4rem 0 2rem 0;
    text-align: initial;
    @media only screen and (max-width: 576px) {
      font-size: 2rem;
      margin: 3rem 0 2rem 0;
    }
  }

  .return-to-top {
    text-align: left;
    padding-top: 1rem;

    a {
      opacity: 0.7;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: 0.5px;
      margin-top: 50px;
      cursor: pointer;
    }
  }

  .box {
    p {
      padding-top: .5rem;
    }
  }

  @media only screen and (max-width: 576px) {
    .even-order {
      order: -1;
    }
  }
}
