.chips {
	.chip {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 8px 6px;
		border-radius: 24px;
		word-spacing: .2rem;
		line-height: 90%;
		text-transform: uppercase;
		font-size: 12px;

		&.chip-blue, &.chip-prestamos {
			color: white;
			background: #015BB0;
		}
		&.chip-green {
			color: white;
			background: #3E6D31;
		}
		&.chip-yellow {
			color: black;
			background: #FFC809;
		}
	}
}
