.blog-posts {

  .blog-posts-placeholder {
    width: 100%;
    position: relative;
    background: conic-gradient(from 180deg at 50% 50%, #F1F1F1 0deg, #FFC809 360deg);
  }

	header {
		text-align: center;
	}

	.blog-posts-list {
		margin: 56px 0;
		justify-content: center;
		align-items: flex-start;
		gap: 16px;
		display: inline-flex;
		flex-wrap: wrap;

    @media screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    a {
      width: 100%;
      height: 100%;

      @media screen and (min-width: 1024px) {
        width: auto;
      }
    }

		article {
			width: 100%;
      height: 100%;
      position: relative;

			header {
				display: flex;

        img {
          width: 100%;

          @media screen and (min-width: 768px) {
            height: 170px;
          }
        }
			}

			.blog-post-preview {
				display: flex;
				flex-direction: column;
				gap: 4px;
				padding: .5rem;

        @media screen and (max-width: 576px) {
          background: white;
          width: 100%;
        }

        .chip {
          color: white;
          border-radius: 24px;

          &.chip-blue {
            background: var(--fi-color-theme-blue);
          }

          &.chip-yellow {
            background: var(--fi-color-theme-yellow);
          }

          &.chip-green {
            background: var(--fi-color-theme-green);
          }

          &.chip-orange {
            background: var(--fi-color-theme-orange);
          }
        }

        .label {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 19.2px;

          @media screen and (max-width: 576px) {
            font-size: 14px;
            line-height: 16.8px;
          }
        }

        time {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14.4px;
        }
			}
		}
	}

	footer {
		text-align: center;
		fi-button {
			button {
				&.sc-fi-button {
					width: auto;
				}
			}
		}
	}
}
